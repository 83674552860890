<script setup>
import { Toaster, toast } from 'vue-sonner';
import { ref, onMounted } from 'vue';
import { getToastMessage, removeToastMessage } from '@utils/toastMessage';

const toastMessage = ref(null);

onMounted(() => {
  toastMessage.value = getToastMessage();
  if (toastMessage.value?.type === 'success') {
    toast.success(toastMessage.value.message);
    removeToastMessage();
  }
  if (toastMessage.value?.type === 'error') {
    toast.error(toastMessage.value.message);
    removeToastMessage();
  }
});
</script>

<template>
  <div>
    <Toaster
      richColors
      position="top-right" />
  </div>
</template>
